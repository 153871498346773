<template>
    <!-- 收货地址 -->
    <div class="customer_box">
        <div class="customer_search_box">
            <div class="search-item">

            </div>
            <div class="search-item">

            </div>
            <div class="btn_box">
                <el-button type="primary" size="small" plain @click="buildPerson()">添加委托人</el-button>
            </div>
        </div>
        <div class="table_mode">
            <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
                <el-table-column prop="name" label="委托人姓名" min-width="140" :show-overflow-tooltip="true" align="center">
                </el-table-column>
                <el-table-column prop="startDate" label="委托开始日期" min-width="140" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="endDate" label="委托结束日期" min-width="140" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="remark" label="备注" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="img" label="附件" min-width="200" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        <!-- <a :href="scope.row.img" target="_blank">{{scope.row.img.includes('pdf')?'委托书.PDF':'委托书.图片'}}</a> -->
                        <el-link v-for="(item,index) in scope.row.imgs" :key="index" :underline="false" type="primary" @click="viewDetail(item)">{{'委托书'+(index+1) }} <span v-if="index<scope.row.imgs.length-1">,</span></el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="statusTitle" label="状态" :show-overflow-tooltip="true" align="center">
                </el-table-column>
                <el-table-column prop="reason" label="审核备注" :show-overflow-tooltip="true" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true" width="150" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作 " width="150" align="center">
                    <template slot-scope="scope">
                        <div class="table_btn">
                            <el-popconfirm title="作废后此委托内容将会失效，你还要继续吗？" @onConfirm="typeClick(scope.row.id,2)" v-if="scope.row.statusTitle=='生效中'&& scope.row.statusTitle!='已失效'">
                                <div class="btn_item" slot="reference" v-if="scope.row.statusTitle=='生效中'">作废</div>
                            </el-popconfirm>
                            <el-popconfirm title="撤销后此委托内容将会删除，你还要继续吗？" @onConfirm="typeClick(scope.row.id,1)" v-if="scope.row.statusTitle=='审核中'&& scope.row.statusTitle!='已失效'">
                                <div class="btn_item" slot="reference" v-if="scope.row.statusTitle=='审核中'">撤销并删除</div>
                            </el-popconfirm>
                            <!-- <el-popconfirm title="撤销后此委托内容将会删除，你还要继续吗？" @onConfirm="reset(scope.row.id)" v-if="scope.row.statusTitle=='审核中'">
                              <div class="btn_item" v-if="scope.row.statusTitle=='审核中'" >撤销并删除</div>
                            </el-popconfirm> -->

                            <el-popconfirm icon="el-icon-error" icon-color="red" title="删除后将不可恢复，确定要操作吗？" v-if="scope.row.statusTitle=='审核驳回'&& scope.row.statusTitle!='已失效'" @onConfirm="typeClick(scope.row.id,3)">
                                <div class="btn_item" slot="reference" v-if="scope.row.statusTitle=='审核驳回'&& scope.row.statusTitle!='已失效'">删除</div>
                            </el-popconfirm>
                        </div>
                    </template>
                </el-table-column>
                <div slot="empty" class="nodata_box">
                    <img src="@/assets/images/index/nodata.png" />
                    <span class="txt">暂无数据哦~</span>
                </div>
            </el-table>
        </div>
        <el-dialog title="新增付款委托书" :visible.sync="personDialog" :close-on-click-modal="false" width="30%" @closed="handleClose">
            <!-- <div  class="alert"><i style="color:#ffaa00;margin-right:10px;" class="el-icon-warning"></i> <span>新增、编辑的收货地址需要后台审核通过后才可使用</span></div> -->
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">

                <el-form-item label="委托人姓名" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="委托开始结束日期" prop="date">
                    <el-date-picker v-model="ruleForm.date" type="daterange" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="委托日期" prop="mobile">
                    <el-input v-model="ruleForm.mobile" placeholder="请输入"></el-input>
                </el-form-item> -->
                <el-form-item label="货款支付授权委托书" prop="imgs">
                    <!-- <el-upload class="upload-demo" action="#" ref="uploadCom" :auto-upload="false" :limit="3" :on-remove="handleRemove" :on-change="fileChange" :before-remove="beforeRemove" :file-list="fileList">
                        <el-button size="small" type="primary">点击上传</el-button>
                        
                    </el-upload> -->
                    <el-upload class="upload-demo" drag action="#" ref="uploadCom" :auto-upload="false" :limit="3" :on-remove="handleRemove" :on-change="fileChange" :before-remove="beforeRemove" :file-list="fileList">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div slot="tip" class="el-upload__tip">支持扩展名： .pdf .jpg .png，请点击 <el-link type="primary" @click="downLoadTem">下载模版</el-link></div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" v-model="ruleForm.remark" maxlength="200" show-word-limit placeholder="请输入备注"></el-input>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetForm">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </span>
        </el-dialog>
        <div class="pagination_box">
            <el-pagination background layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 30, 40]" :page-size.sync="filterinfo.pageSize" :current-page.sync="filterinfo.page" :total="total" @current-change="getbookList" @size-change="getbookList(1)">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { handlerTableData } from '@/utils/common'
export default {
    name: 'payBook',
    data() {
        return {
            loading: false,
            personDialog: false,
            // addressTitle: '新增付款委托书',
            filterinfo: {
                page: 1,
                pageSize: 10,
            },
            dialogVisible: false,
            tableData: [],
            cityOptions: [],
            fileList: [],
            uidList: [],
            areaOptions: [],
            provinceOptions: [],
            imgHideUpload: false,
            imgLimitCount: 1,
            total: 0,
            ruleForm: {
                remark: '',
                name: '',
                date: '',
                startDate: "",
                endDate: "",
                imgs: [],
            },
            rules: {
                name: [
                    { required: true, message: '请输入收货人姓名', trigger: 'blur' },
                ],
                date: [
                    { required: true, message: '请输入开始结束日期', trigger: 'blur' },

                ],
                imgs: [
                    { required: true, message: '请上传文件', trigger: 'change' },

                ],


            }
        };
    },
    created() {

    },
    mounted() {
        this.getbookList()
    },
    methods: {
        beforeUpload(file) {
            console.log(file.raw.type, 'file.raw.type');
            const acceptTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'];// 将支持的文件类型配置在这个数组中
            const isFormat = acceptTypes.includes(file.raw.type);
            const maxsize = 2;// 定义文件大小不超过10M
            const isLtMaxsize = file.size / 1024 / 1024 < maxsize;
            if (!isFormat) {
                this.$message.error('导入文件格式错误，请重新选择文件导入');
            }
            if (!isLtMaxsize) {
                this.$message.error(`文件大于${maxsize}M，请重新选择文件导入`);
            }
            return isFormat && isLtMaxsize;
        },
        downLoadTem() {
            window.open('https://b2b-img.hrjkjt.com/货款支付授权委托书.docx')
        },
        viewDetail(img) {
            window.open(img)
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
            this.fileList = fileList
            // 回调file中包含图片uid

            // 根据当前要删除图片uid，获取该uid在uid数组中所对应的下标
            const index = this.uidList.findIndex(item => item === file.uid)
            console.log(index, 'index');

            // 在imageIds数组中找到它！干掉它！
            this.ruleForm.imgs.splice(index, 1)
            this.uidList.splice(index, 1)
        },
        async fileChange(file, fileList) {
            if (!this.beforeUpload(file)) {
                this.ruleForm.imgs = []
                this.uidList = []
                this.$refs.uploadCom.clearFiles();
                return;
            }
            console.log(file);

            const fd = new FormData()
            fd.append('paymentEntrustFile', file.raw)
            const res = await this.$api.uploadBook(fd)
            if (res.data.code == 200) {
                console.log(res.data.data.img);
                this.ruleForm.imgs.push(res.data.data.img)
                this.uidList.push(file.uid)
            }
            this.$refs.ruleForm.validate()
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },



        async typeClick(id, doType) {
            const res = await this.$api.doBook({
                id,
                doType
            })
            if (res.data.code === 200) {
                this.$message.success('操作成功')
                this.getbookList()
            }
        },
        submitForm() {
            console.log(this.ruleForm);
            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    this.ruleForm.startDate = this.ruleForm.date[0]
                    this.ruleForm.endDate = this.ruleForm.date[1]
                    //  if(new Date(this.ruleForm.endDate).getTime() < new Date(this.ruleForm.startDate).getTime() ){
                    //     this.$message.error('开始日期不能大于结束日期')
                    //  }
                    const res = await this.$api.addPayBook(this.ruleForm)

                    if (res.data.code === 200) {
                        this.resetForm()
                        this.$message.success('添加成功')
                        this.getbookList()
                    }


                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //清空数据
        resetForm() {
            this.$refs.ruleForm.resetFields();
            this.ruleForm.img = ''
            this.ruleForm.name = ''
            this.ruleForm.date = ''
            this.fileList = []
            this.personDialog = false
        },
        handleClose() {
            this.resetForm()
            this.ruleForm.remark = ''
        },
        buildPerson(type) {
            this.personDialog = true
        },
        provinceChange() {
            this.getAddress(this.ruleForm.provinceId, 'cityOptions')
            this.ruleForm.cityId = ''
            this.ruleForm.areaId = ''
            this.cityOptions = []
            this.areaOptions = []
        },
        cityChange() {
            this.getAddress(this.ruleForm.cityId, 'areaOptions')
            this.ruleForm.areaId = ''
            this.areaOptions = []
        },
        //获取全国地址
        async getAddress(parentId, option) {
            const res = await this.$api.getAllAddress({ parentId })
            // if(res.data.code!=200) return this.$message.error(res.data.msg)
            this[option] = res.data.data
        },
        // 获取我的客户列表
        getbookList(num) {
            this.loading = true
            this.$api.bookList(this.filterinfo).then((res) => {
                this.loading = false
                if (res.data.code === 200) {
                    this.tableData = handlerTableData(res.data.data.list)
                    this.total = res.data.data.total

                    // this.tableData = res.data.data.list
                    // this.options = res.data.data.shopType
                    // this.attributeOptions = res.data.data.attr
                }
            });
        },
        // handleSortChange({ column, prop, order }) {
        //     if (order == 'ascending') {
        //         this.filterinfo.orderBy = "asc"
        //     } else {
        //         this.filterinfo.orderBy = "desc"
        //     }
        //     this.getbookList()
        // }
    }
};
</script>
<style lang="scss" >
.customer_box {
    position: relative;
    padding-top: 4px;
}
.message_btn {
    border: 1px solid #1a9dff;
    color: #1a9dff;
    &:hover {
        border: 1px solid #337cff;
        color: #337cff;
    }
}

.imgHide .el-upload--picture-card {
    display: none;
}
.alert {
    display: flex;
    align-items: center;
    // width: 600px;
    margin-left: 20px;
    // justify-content: center;
    border: 1px solid #fff3c6;
    background: #fffbe6;
    padding: 5px;
    margin-bottom: 20px;
}
.el-upload__tip{
    margin-top: 0 !important;
}
.el-upload-dragger .el-upload__text{
    font-size: 11px !important;
}
.el-upload-dragger{
    width: 160px;
    height: 80px;
    padding: 10px 0;
}
.el-upload-dragger .el-icon-upload{
    line-height: 0;
    margin: 0;
    font-size: 36px;
}
</style>